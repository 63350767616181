import React from 'react';
import { Input, Tooltip } from 'antd';

import './InputHospitalInfo.scss';

interface IInputHospitalInfo {
  displayName: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  maxLength?: number;
  hasError?: boolean;
}

export default function InputHospitalInfo({
  displayName,
  placeholder,
  onChange,
  value,
  disabled,
  maxLength,
  hasError = false,
}: IInputHospitalInfo) {
  return (
    <div className="InputHospitalInfo__container">
      <h3 className="InputHospitalInfo__container--display-name">{displayName}</h3>
      <Tooltip
        title="최대 12자리 숫자만 가능합니다"
        color="red"
        placement="right"
        visible={hasError}
      >
        <Input
          className="InputHospitalInfo__container--input"
          type="text"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
          maxLength={maxLength}
        />
      </Tooltip>
    </div>
  );
}
