import { createAction, ActionType } from 'typesafe-actions';

import { IResCharge } from './charge.models';

export const SET_CHARGE_LISTS = 'SET_CHARGE_LISTS';
export const setChargeLists = createAction(SET_CHARGE_LISTS)<IResCharge>();

export const SET_HOSPITAL_POINT = 'SET_HOSPITAL_POINT';
export const setHospitalPoint = createAction(SET_HOSPITAL_POINT)<number>();

const actions = { setChargeLists, setHospitalPoint };

// action type
export type ChargeActions = ActionType<typeof actions>;
