import React from 'react';
import { Tag } from 'antd';

interface ITag {
  children: React.ReactNode;
  color:
    | 'magenta'
    | 'red'
    | 'volcano'
    | 'orange'
    | 'gold'
    | 'lime'
    | 'green'
    | 'cyan'
    | 'blue'
    | 'geekblue'
    | 'purple'
    | 'pink'
    | 'yellow'
    | 'success'
    | 'processing'
    | 'error'
    | 'default'
    | 'warning'
    | (string & {});
}

export default function index({ children, color }: ITag) {
  return <Tag color={color}>{children}</Tag>;
}
