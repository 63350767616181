import { createAction, ActionType } from 'typesafe-actions';

import { InputPayload, RestPayload } from './filter.models';

export const SET_RESERVATION_FILTER = 'SET_RESERVATION_FILTER';
export const setReservationFilter = createAction(SET_RESERVATION_FILTER)<InputPayload>();

export const RESET_RESERVATION_FILTER = 'RESET_RESERVATION_FILTER';
export const resetReservationFilter = createAction(RESET_RESERVATION_FILTER)<RestPayload>();

export const SET_CHARGE_FILTER = 'SET_CHARGE_FILTER';
export const setChargeFilter = createAction(SET_CHARGE_FILTER)<InputPayload>();

export const RESET_CHARGE_FILTER = 'RESET_CHARGE_FILTER';
export const resetChargeFilter = createAction(RESET_CHARGE_FILTER)();

const actions = {
  setReservationFilter,
  resetReservationFilter,
  setChargeFilter,
  resetChargeFilter,
};

// action type
export type FilterActions = ActionType<typeof actions>;
