import gql from 'graphql-tag';

export const SendEmail = gql`
  mutation SendEmail($input: EmailInput!) {
    sendEmail(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;
