import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';

import { GET_CHARGE_LISTS, GET_HOSPITAL_POINT } from 'query/charge.query';
import { IResCharge, IResHospitalPoint } from 'modules/charge/charge.models';
import { RootState } from 'modules/rootState';
import { setChargeLists, setHospitalPoint } from 'modules/charge/charge.actions';

export default function useCharge() {
  const ALERT_PRICE = -300000;
  const dispatch = useDispatch();
  const { data, pagination, point, pointFetching } = useSelector(
    (state: RootState) => state.charge
  );

  const [getChargeLists, { loading }] = useLazyQuery<IResCharge>(GET_CHARGE_LISTS, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => dispatch(setChargeLists(res)),
  });

  const [getHospitalPoint] = useLazyQuery<IResHospitalPoint>(GET_HOSPITAL_POINT, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      dispatch(setHospitalPoint(res.hospitalPoint.balance));
      if (res.hospitalPoint.balance < ALERT_PRICE) {
        message.error('충전금이 부족합니다. 충전 후 이용해주시기 바랍니다.');
      }
    },
  });

  return {
    getChargeLists,
    getHospitalPoint,
    data,
    pagination,
    loading,
    point,
    fetchPoint: pointFetching,
  };
}
