import React from 'react';

import ImgKakao from '../../../assets/images/consult_large_yellow_pc.png';
import { KAKAO_CHAT } from 'utils/constants';

interface IKakaoChat {
  fixed?: boolean;
}

export default function KakaoChat({ fixed = false }: IKakaoChat) {
  return (
    <a href={KAKAO_CHAT} target="_blank" rel="noopener noreferrer">
      <img src={ImgKakao} alt="톡상담" />
    </a>
  );
}
