export function amountColors(val: number = 0) {
  if (val === 0) {
    return '';
  } else if (val > 0) {
    return 'number-positive';
  } else if (val < 0) {
    return 'number-negative';
  } else {
    return '';
  }
}
