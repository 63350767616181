import React from 'react';
import { Table as AntdTable } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { QueryLazyOptions } from '@apollo/client';
import dayjs from 'dayjs';
import classNames from 'classnames';

import './ChargeTable.scss';
import {
  Charge,
  ChargeAction,
  ChargeActionStrings,
  Pagination,
} from 'modules/charge/charge.models';
import useFilterModel from 'hooks/useFilterModel';
import { amountColors } from 'utils/amountColors';

interface IChargeTable {
  isFetching: boolean;
  dataSource: Charge[];
  pagination: Pagination;
  getChargeLists: (option: QueryLazyOptions<any>) => void;
}

export default function ChargeTable({
  isFetching,
  dataSource,
  pagination,
  getChargeLists,
}: IChargeTable) {
  const [, chargeFilterModel] = useFilterModel();

  const columns: ColumnsType<Charge> = [
    {
      title: '일자',
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: 200,
      className: 'ChargeTable__header--timestamp',
      render: (timestamp) => <span>{dayjs(timestamp).format('YYYY.MM.DD HH:mm')}</span>,
    },
    {
      title: '구분',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (action: ChargeActionStrings) => <span>{ChargeAction[action]}</span>,
    },
    {
      title: '내역',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '금액',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      className: 'ChargeTable__header--amount',
      render: (amount: number) => (
        <span className={classNames(amountColors(amount))}>
          {amount.toLocaleString('ko', { maximumFractionDigits: 1 })}
        </span>
      ),
    },
    {
      title: '잔액',
      dataIndex: 'balance',
      key: 'balance',
      width: 150,
      className: 'ChargeTable__header--balance',
      render: (balance: number) => (
        <span>{balance.toLocaleString('ko', { maximumFractionDigits: 1 })}</span>
      ),
    },
  ];

  const antdPagination = {
    current: pagination.currentPage,
    total: pagination.total,
  };

  return (
    <>
      <AntdTable<Charge>
        loading={{ size: 'large', spinning: isFetching }}
        dataSource={dataSource}
        columns={columns}
        rowKey={(record) => record.id}
        pagination={{ ...antdPagination, position: ['bottomCenter'] }}
        onChange={(data) => {
          getChargeLists({
            variables: {
              filter: chargeFilterModel,
              pagination: {
                currentPage: data.current,
              },
            },
          });
        }}
      />
    </>
  );
}
