import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import { IChargeState } from './charge.models';
import { SET_CHARGE_LISTS, SET_HOSPITAL_POINT } from './charge.actions';

const initialState = {
  data: [],
  pagination: {
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    total: 0,
  },
  isFetching: false,
  point: 0,
  pointFetching: true,
};

const chargeReducer = createReducer<IChargeState, RootAction>(initialState, {
  [SET_CHARGE_LISTS]: (state, action) => ({
    ...state,
    data: action.payload.hospitalPointTransactions.data,
    pagination: action.payload.hospitalPointTransactions.pagination,
  }),

  [SET_HOSPITAL_POINT]: (state, action) => ({
    ...state,
    point: action.payload,
    pointFetching: false,
  }),
});

export default chargeReducer;
