import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';

import { MUTATION_LOGIN } from 'query/auth.query';
import { LoginMutation } from 'generated/graphql';
import { setLogin } from 'modules/auth/auth.actions';
import AuthHelper from 'utils/AuthHelper';

export default function useLogin() {
  const dispatch = useDispatch();

  useEffect(() => {
    AuthHelper.resetToken();
  }, []);

  const [handleSubmit, { loading }] = useMutation<LoginMutation>(MUTATION_LOGIN, {
    onCompleted: (res) => dispatch(setLogin(res)),
    onError: (error) => console.log('LoginMutation', error),
  });

  return {
    handleSubmit,
    loading,
  };
}
