import { createAction, ActionType } from 'typesafe-actions';

import { IEventReservations } from './reservation.models';

export const SET_RESERVATIONS = 'SET_RESERVATIONS';
export const setReservations = createAction(SET_RESERVATIONS)<IEventReservations>();

export const SET_FETCHING = 'SET_FETCHING';
export const setFetching = createAction(SET_FETCHING)<boolean>();

const actions = { setReservations, setFetching };

// action type
export type ReservationActions = ActionType<typeof actions>;
