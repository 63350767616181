import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Input, Select, Dropdown, Menu } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { QueryLazyOptions } from '@apollo/client';

import './index.scss';
import { Path, Status } from 'modules/filter/filter.models';
import { DatePicker } from 'components';
import useFilter from 'hooks/useFilter';
import { RootState } from 'modules/rootState';
import useFilterModel from 'hooks/useFilterModel';

const { Option } = Select;

interface IFilter {
  getReservationsToExcel: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void;
}

export default function Filter({ getReservationsToExcel }: IFilter) {
  const [openForm, setOpenForm] = useState(false);
  const [displayButton, setDisplayButton] = useState('고객검색');
  const { reservationFilter, filterModel, onChange, getReservations, clear } = useFilter();
  const [reservationFilterModel] = useFilterModel();
  const { status, path, name, phone } = reservationFilter;
  const { range } = useSelector((state: RootState) => state.filter.reservationFilter);

  const onClickButtonSubmit = useCallback(() => {
    getReservations({ variables: { filter: filterModel } });
    setOpenForm(false);
    setDisplayButton(name.trim() === '' && phone.trim() === '' ? '고객검색' : `${name} ${phone}`);
  }, [name, phone, filterModel, getReservations]);

  const menu = (
    <Menu className="filter__dropdown">
      <div className="filter__dropdown--input-wrapper">
        <div>
          <Input
            placeholder="이름 검색"
            value={name}
            onChange={(e) => onChange('name', e.target.value)}
            onKeyUp={(e) => e.key === 'Enter' && onClickButtonSubmit()}
            className="filter__dropdown--input"
            style={{ marginBottom: '1rem' }}
          />
        </div>
        <div>
          <Input
            placeholder="연락처 검색"
            value={phone}
            onChange={(e) => onChange('phone', e.target.value)}
            onKeyUp={(e) => e.key === 'Enter' && onClickButtonSubmit()}
            className="filter__dropdown--input"
          />
        </div>
      </div>
      <div className="filter__dropdown--bottom">
        <Button
          type="link"
          className="filter__dropdown--clear"
          onClick={(e) => {
            clear('filter');
            getReservations({
              variables: { filter: { ...filterModel, username: '', userPhonenum: '' } },
            });
            setOpenForm(false);
            setDisplayButton('고객검색');
          }}
        >
          지우기
        </Button>
        <Button type="primary" className="filter__dropdown--submit" onClick={onClickButtonSubmit}>
          완료
        </Button>
      </div>
    </Menu>
  );

  return (
    <div className="filter__container">
      <div className="filter__container--select">
        <Select
          style={{ width: '100%' }}
          onChange={(value) => onChange('status', value)}
          showArrow={false}
          value={status}
          listHeight={369}
        >
          {Object.entries(Status).map(([key, value]) => (
            <Option value={key} key={key}>
              {value}
            </Option>
          ))}
        </Select>
      </div>
      <div className="filter__container--select">
        <Select
          value={path}
          onChange={(value) => onChange('path', value)}
          showArrow={false}
          style={{ width: '100%' }}
        >
          {Object.entries(Path).map(([key, value]) => (
            <Option value={key} key={key}>
              {value}
            </Option>
          ))}
        </Select>
      </div>
      <div className="filter__container--picker">
        <DatePicker.RangePicker
          locale={locale}
          value={range}
          onChange={(value) => {
            const calEndDay = value?.map((v, idx) => {
              if (idx === 1) {
                return v?.endOf('day');
              }

              return v?.startOf('day');
            });

            onChange('range', calEndDay || [null, null]);
          }}
          style={{ width: '100%', height: '100%' }}
          ranges={{
            오늘: [dayjs(), dayjs()],
            이번주: [dayjs().startOf('week'), dayjs().endOf('week')],
            이번달: [dayjs().startOf('month'), dayjs().endOf('month')],
            '3개월': [dayjs().subtract(3, 'month'), dayjs()],
            '6개월': [dayjs().subtract(6, 'month'), dayjs()],
            '1년': [dayjs().subtract(12, 'month'), dayjs()],
          }}
        />
      </div>
      <div style={{ marginLeft: '1rem' }}>
        <Dropdown
          overlay={menu}
          trigger={['click']}
          visible={openForm}
          onVisibleChange={(visible) => setOpenForm(visible)}
        >
          <Button className="filter__dropdown--button" style={{ height: '100%' }}>
            {displayButton}
          </Button>
        </Dropdown>
      </div>
      <div className="filter__container--clear">
        <span
          onClick={() => {
            clear('unmount');
            setDisplayButton('고객검색');
            getReservations();
          }}
        >
          검색 초기화
        </span>
      </div>
      <div className="filter__container--clear">
        <span
          onClick={() => {
            const agree = window.confirm(
              '엑셀 내보내기를 통해 저장한 고객의 개인정보에 대한 관리 및 보호책임은 모두 병원에게 있으며, 이와 관련한 문제 발생 시 전적으로 병원의 책임임을 동의합니다.'
            );

            if (agree) {
              getReservationsToExcel({
                variables: {
                  filter: reservationFilterModel,
                  pagination: {
                    perPage: 100000,
                    currentPage: 1,
                  },
                },
              });
            }
          }}
        >
          엑셀 내보내기
        </span>
      </div>
    </div>
  );
}
