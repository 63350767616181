import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import './index.scss';
import { ButtonSave, InputHospitalInfo, InputHospitalInfoMultiple, Spinner } from 'components';
import { changeHospitalInput } from 'modules/auth/auth.actions';
import useMyHospital from 'hooks/useMyHospital';

export default function Hospital() {
  const dispatch = useDispatch();
  const [hospitalPhoneError, setHospitalPhoneError] = useState(false);
  const { handleSubmit, name, tel, phones, disabled, loading } = useMyHospital();

  const changeHospitalPhone = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const regex = /^[0-9]*$/;
      dispatch(changeHospitalInput({ key: 'tel', value: e.target.value }));
      setHospitalPhoneError(!regex.test(e.target.value));
    },
    [dispatch]
  );

  const changePhones = useCallback(
    (value: string, idx: number) => {
      const newPhones = Array.from(phones as string[]);
      newPhones[idx] = value;
      dispatch(changeHospitalInput({ key: 'phones', value: newPhones }));
    },
    [dispatch, phones]
  );

  return (
    <div className="Hospital__container">
      {loading && <Spinner />}
      <InputHospitalInfo displayName="병원명" value={name} disabled />
      <InputHospitalInfo
        displayName="대표번호"
        value={tel}
        onChange={changeHospitalPhone}
        maxLength={12}
        hasError={hospitalPhoneError}
      />
      <InputHospitalInfoMultiple
        displayName="담당자 번호"
        phones={phones as string[]}
        changePhones={changePhones}
        placeholder="휴대폰 번호 (숫자만 입력하세요)"
      />
      <p className="Hospital__container--desc">
        신규 상담 및 예약 접수 시, 입력된 번호로 카카오톡 또는
      </p>
      <p className="Hospital__container--desc">문자 알림을 보내드립니다.</p>
      <div style={{ marginTop: '6rem' }}>
        <ButtonSave onClick={() => handleSubmit()} disabled={disabled}>
          저장하기
        </ButtonSave>
      </div>
    </div>
  );
}
