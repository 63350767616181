import React from 'react';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';

import './ButtonFat.scss';

interface IButtonFat {
  children: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  type?: ButtonType;
  disabled?: boolean;
}

export default function ButtonFat({
  children,
  className,
  onClick,
  type = 'default',
  disabled,
}: IButtonFat) {
  return (
    <Button type={type} className={className} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
}
