import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createRootReducer from 'modules/rootReducer';
import { rootSaga } from 'modules/rootSaga';

export const history = createBrowserHistory();

export function configureStore(initialState = undefined) {
  const reduxRouterMiddleware = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware({
    context: { history },
  });

  const middlewares = [reduxRouterMiddleware, sagaMiddleware];

  let composeEnhancer = null;
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  } else {
    composeEnhancer = compose;
  }

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancer(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
