import React from 'react';

import { ReservationTable, Filter } from 'components';
import useReservation from 'hooks/useReservation';
import PointNotEnough from 'components/molecules/Modal/PointNotEnough';
import useFilterModel from 'hooks/useFilterModel';
import DisabledHospital from 'components/molecules/Modal/DisabledHospital';

export default function Reservation() {
  const {
    isFetching,
    dataSource,
    pagination,
    // filterModel,
    visibleNotEnough,
    visibleDisabledHospital,
    updateReservation,
    getReservations,
    getReservationsToExcel,
  } = useReservation();

  const [reservationFilterModel] = useFilterModel();

  return (
    <>
      <Filter getReservationsToExcel={getReservationsToExcel} />
      <ReservationTable
        isFetching={isFetching}
        dataSource={dataSource}
        updateReservation={updateReservation}
        getReservations={getReservations}
        pagination={pagination}
        filterModel={reservationFilterModel}
      />
      {/* <PointNotEnough visible={visibleNotEnough} /> */}
      <DisabledHospital visible={visibleDisabledHospital} />
    </>
  );
}
