import React from 'react';

import './index.scss';
import Logo from '../../../assets/images/footer-logo.svg';
import KakaoChat from 'components/atoms/KakaoChat';

export default function LoginFooter() {
  return (
    <div className="login__footer">
      <div className="login__footer--logo">
        <img src={Logo} alt="오닥터 로고" />
      </div>
      <div className="login__footer--content">
        <div className="footer-row">
          <span>대표 고석호</span>
          <span className="footer-text-divider">|</span>
          <span>사업자번호 286-14-00620</span>
        </div>
        <div className="footer-row">
          <span>서울시 강남구 언주로 85길 29 5층</span>
          <span className="footer-text-divider">|</span>
          <span>전화 02-3443-1357</span>
          <span className="footer-text-divider">|</span>
          <span>이메일 partner@odoctor.co.kr</span>
        </div>
        <div className="footer-row terms">
          <a
            href="https://www.notion.so/398de611d5f647be848c5976d0a33065"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>이용약관</span>
          </a>
          <a
            href="https://www.notion.so/a99a16d326a5427fa26d2aedf06e6a54"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span style={{ marginLeft: '1rem' }}>개인정보처리방침</span>
          </a>
        </div>
        <div className="footer-row">@ 2020 Odoctor all rights reserved.</div>
      </div>
      <div className="login__footer--kakao">
        <KakaoChat />
      </div>
    </div>
  );
}
