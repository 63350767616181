// TODO: localStorage 안되는 브라우저 대응
class AuthHelper {
  getTokens() {
    try {
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');

      return { token, refreshToken };
    } catch (error) {
      console.error(error, 'getTokens');
      return { token: '', refreshToken: '' };
    }
  }

  getToken(item: string) {
    try {
      return localStorage.getItem(item);
    } catch (error) {
      console.error(error, 'getToken');
      return null;
    }
  }

  setToken(item: string, value: string) {
    localStorage.setItem(item, value);
  }

  resetToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }

  validateToken() {
    try {
      const token = this.getToken('token');
      const refreshToken = this.getToken('refreshToken');

      return token !== null && refreshToken !== null;
    } catch (err) {
      this.resetToken();
      return false;
    }
  }
}

export default new AuthHelper();
