import React from 'react';
import { Button } from 'antd';

import './ButtonSave.scss';

interface IButtonSave {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
}

export default function ButtonSave({ children, onClick, disabled }: IButtonSave) {
  return (
    <Button type="primary" className="ButtonSave" onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
}
