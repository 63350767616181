import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import { IEventReservationStatus } from './reservation.models';
import { SET_FETCHING, SET_RESERVATIONS } from './reservation.actions';

const initialState = {
  data: [],
  pagination: {
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    total: 0,
  },
  isFetching: false,
};

const reservationReducer = createReducer<IEventReservationStatus, RootAction>(initialState, {
  [SET_FETCHING]: (state, action) => ({ ...state, isFetching: action.payload }),

  [SET_RESERVATIONS]: (state, action) => ({
    data: action.payload.data,
    pagination: action.payload.pagination,
    isFetching: false,
  }),
});

export default reservationReducer;
