import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import { RootState } from './rootState';
import filterReducer from './filter/filter.reducer';
import authReducer from './auth/auth.reducer';
import reservationReducer from './reservation/reservation.reducer';
import chargeReducer from './charge/charge.reducer';

const createRootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    filter: filterReducer,
    auth: authReducer,
    reservation: reservationReducer,
    charge: chargeReducer,
  });

export default createRootReducer;
