import { ChargeActionStrings } from 'modules/charge/charge.models';

export enum Status {
  A01 = '상태 (전체)',

  N01 = '신규',

  C01 = '재연락필요',
  C02 = '부재중',

  V01 = '내원예정',
  V03 = '내원안함',

  T02 = '치료완료',
  T01 = '치료안함',

  F01 = '취소',
  F02 = '중복',
  F03 = '결번',
}

export enum Path {
  All = '경로 (전체)',
  Special = '스페셜픽',
  Weekly = '위클리픽',
}

export enum ContactableTime {
  ANYTIME = '언제든지',
  MORNING = '오전',
  AFTERNOON = '오후',
}

export type UserAgeGroupStrings = keyof typeof UserAgeGroup;

export enum UserAgeGroup {
  underTen = '미성년자',
  teenager = '10대',
  twenties = '20대',
  thirties = '30대',
  forties = '40대',
  fifties = '50대',
  overSixties = '60대 이상',
}

export type StatusStrings = keyof typeof Status;
export type PathStrings = keyof typeof Path;
export type ContactableTimeStrings = keyof typeof ContactableTime;

interface ReservationFilter {
  status: StatusStrings;
  path: PathStrings;
  range: any;
  name: string;
  phone: string;
}

interface ChargeFilter {
  range: any;
  action: ChargeActionStrings;
}

export interface IFilter {
  reservationFilter: ReservationFilter;
  chargeFilter: ChargeFilter;
}

export type InputPayload = {
  key: string;
  value: any;
};

export type RestPayload = 'unmount' | 'filter';
