import { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import { RootState } from 'modules/rootState';
import { setReservationFilter, resetReservationFilter } from 'modules/filter/filter.actions';
import { IResponse } from 'modules/reservation/reservation.models';
import { QUERY_GET_RESERVATIONS } from 'query/reservation.query';
import { setFetching, setReservations } from 'modules/reservation/reservation.actions';
import useFilterModel from './useFilterModel';

export default function useFilter() {
  const initialMount = useRef(true);
  const dispatch = useDispatch();
  const { reservationFilter } = useSelector((state: RootState) => state.filter);
  const { status, path, range, name, phone } = reservationFilter;
  const [filterModel] = useFilterModel();

  const [getReservations] = useLazyQuery<IResponse>(QUERY_GET_RESERVATIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => dispatch(setReservations(res.eventReservations)),
  });

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
    } else {
      dispatch(setFetching(true));
      getReservations({ variables: { filter: filterModel } });
    }
    // 상태, 경로, 날짜검색은 업데이트시 바로 검색
  }, [status, path, range, dispatch, getReservations]);

  useEffect(() => {
    return () => {
      dispatch(resetReservationFilter('unmount'));
    };
  }, [dispatch]);

  const onChange = useCallback(
    (key, value) => {
      dispatch(setReservationFilter({ key, value }));
    },
    [dispatch]
  );

  const clear = useCallback(
    (type: 'filter' | 'unmount') => {
      dispatch(resetReservationFilter(type));
    },
    [dispatch]
  );

  return { reservationFilter, filterModel, onChange, getReservations, clear };
}
