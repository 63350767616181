import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';

import { MUTATION_RESET_PASSWORD } from 'query/auth.query';
import { ResetPasswordMutation } from 'generated/graphql';
import InputLogin from 'components/atoms/Input/InputLogin';
import './ChangePassword.scss';
import ButtonFat from 'components/atoms/Button/ButtonFat';

interface IChangePassword {
  visible: boolean;
  onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancel: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export default function ChangePassword({ visible = false, onOk, onCancel }: IChangePassword) {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isSamePassword, setIsSamePassword] = useState(true);

  useEffect(() => {
    if (visible) {
      setIsSamePassword(true);
    } else {
      setPassword('');
      setRepeatPassword('');
    }
  }, [visible]);

  const [handleSubmit, { loading }] = useMutation<ResetPasswordMutation>(MUTATION_RESET_PASSWORD, {
    onCompleted: (res) => {
      if (res.resetPassword.ok) {
        onCancel();
      }
    },
    onError: (error) => console.log('ResetPasswordMutation', error),
  });

  const onClickButtonChangePassword = useCallback(() => {
    handleSubmit({
      variables: {
        newPassword: password,
      },
    });
  }, [password, handleSubmit]);

  const isDisabled =
    password !== repeatPassword || password.length < 8 || repeatPassword.length < 8;

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onCancel}
      footer={null}
      width={422}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      bodyStyle={{ padding: 0 }}
    >
      <div className="change-password">
        <div className="change-password__header">
          <h1 className="change-password__header--title">비밀번호 변경</h1>
          <div className="change-password__header--icon" onClick={onCancel}>
            <CloseOutlined />
          </div>
        </div>
        <div className="change-password__content">
          <InputLogin
            className="form-input__password"
            type="password"
            value={password}
            placeholder="새 비밀번호 (8자 이상)"
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputLogin
            className="form-input__password"
            type="password"
            value={repeatPassword}
            placeholder="새 비밀번호 확인 (8자 이상)"
            onChange={(e) => {
              setRepeatPassword(e.target.value);
              setIsSamePassword(e.target.value === password);
            }}
            onKeyup={(e) => e.key === 'Enter' && onClickButtonChangePassword()}
          />
          <div
            className={classNames('change-password__content--validation', {
              visible: isDisabled && !isSamePassword,
            })}
          >
            비밀번호가 일치하지 않습니다. (8자 이상)
          </div>
          <ButtonFat
            className="button-fat__password"
            type="primary"
            disabled={isDisabled || loading}
            onClick={onClickButtonChangePassword}
          >
            변경하기
          </ButtonFat>
        </div>
      </div>
    </Modal>
  );
}
