import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'antd/lib/modal/Modal';
import { Button, Input } from 'antd';

import './ReportUser.scss';
import { IReservation } from 'modules/reservation/reservation.models';
import { RootState } from 'modules/rootState';
import dayjs from 'dayjs';
import useSendEmail from 'hooks/useSendEmail';

const { TextArea } = Input;

interface IReportUser {
  visible: boolean;
  record?: IReservation;
  onCancel: React.Dispatch<React.SetStateAction<string>>;
}

export default function ReportUser({ visible, record, onCancel }: IReportUser) {
  const { name } = useSelector((state: RootState) => state.auth.hospital);

  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');

  const { handleSendEmail } = useSendEmail();

  useEffect(() => {
    return () => {
      onCancel('');
    };
  }, []);

  const handleOnSubmit = () => {
    const text = [
      `병원: ${name}`,
      `접수일시: ${dayjs(record?.createdAt).format('YYYY년 MM월 DD일 HH시 mm분')}`,
      `고객이름: ${record?.username}`,
      `전화번호: ${record?.userPhonenum}`,
      `유입경로: ${record?.event.name}`,
      `환불사유: ${reason}`,
    ];

    handleSendEmail({
      variables: {
        input: {
          from: email,
          to: 'partner@odoctor.co.kr',
          subject: `[신고하기] ${name}`,
          text: text.join('\n'),
        },
      },
    });

    onCancel('');
  };

  const emailExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

  return (
    <Modal
      visible={visible}
      closable={false}
      footer={null}
      width={463}
      bodyStyle={{ padding: '3.5rem 2rem 2rem 2rem' }}
      maskClosable={false}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
    >
      <div className="ReportUser__Row">
        <div className="ReportUser__Row--title">신고정보</div>
        <div className="ReportUser__Row--content">
          <div className="ReportUser__Row--content-info">병원명: {name}</div>
          <div className="ReportUser__Row--content-info">
            접수일시: {dayjs(record?.createdAt).format('YYYY.MM.DD HH:mm')}
          </div>
          <div className="ReportUser__Row--content-info">고객이름: {record?.username}</div>
          <div className="ReportUser__Row--content-info">전화번호: {record?.userPhonenum}</div>
          <div className="ReportUser__Row--content-info">유입경로: {record?.event.name}</div>
        </div>
      </div>
      <div className="ReportUser__Row" style={{ margin: '3rem 0 1rem 0' }}>
        <div className="ReportUser__Row--title" style={{ paddingTop: '0.7rem' }}>
          <p>답변 받을</p>
          <p>이메일</p>
        </div>
        <div className="ReportUser__Row--content">
          <Input
            type="email"
            placeholder="답변 받을 이메일을 입력해주세요"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="ReportUser__Row">
        <div className="ReportUser__Row--title" style={{ paddingTop: '2rem' }}>
          환불사유
        </div>
        <div className="ReportUser__Row--content">
          <TextArea
            rows={7}
            placeholder="환불사유를 입력해주세요"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: '4rem' }}>
        <Button
          style={{
            flex: 1,
            height: '6.4rem',
            fontSize: '1.4rem',
            fontWeight: 'bold',
            color: '#b1b3b2',
            marginRight: '1rem',
          }}
          onClick={() => onCancel('')}
        >
          닫기
        </Button>
        <Button
          type="primary"
          style={{
            flex: 1,
            height: '6.4rem',
            fontSize: '1.4rem',
            fontWeight: 'bold',
          }}
          onClick={handleOnSubmit}
          disabled={!emailExp.test(email) || reason.trim() === ''}
        >
          신고완료
        </Button>
      </div>
    </Modal>
  );
}
