import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';

import { RootState } from 'modules/rootState';
import { MyHospitalQuery, UpdateHospitalMutation } from 'generated/graphql';
import { MUTATION_UPDATE_HOSPITAL, QUERY_MY_HOSPITAL } from 'query/auth.query';
import { setMyHospital } from 'modules/auth/auth.actions';

export default function useMyHospital() {
  const dispatch = useDispatch();
  const { name, tel, phones } = useSelector((state: RootState) => state.auth.myHospital);
  const { tel: hTel, phones: hPhones } = useSelector((state: RootState) => state.auth.hospital);

  const [getMyHospital, { loading: hospitalLoading }] = useLazyQuery<MyHospitalQuery>(
    QUERY_MY_HOSPITAL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (res) => dispatch(setMyHospital(res.myHospital as any)),
    }
  );

  useEffect(() => {
    getMyHospital();
  }, [getMyHospital]);

  const [updateHospital, { loading: updateLoading }] = useMutation<UpdateHospitalMutation>(
    MUTATION_UPDATE_HOSPITAL,
    {
      variables: {
        input: {
          tel,
          // 배열 반대로, 빈값 제거
          phones: phones ? [...phones].reverse().filter((phone) => phone !== '') : [],
        },
      },
      onCompleted: () => getMyHospital(),
      onError: (error) => console.log('UpdateHospitalMutation', error),
    }
  );

  const handleSubmit = useCallback(() => {
    updateHospital();
  }, [updateHospital]);

  // submit button validation
  const regex = /01[01789]\d{4}\d{4}/;
  let disabled = true;
  if (phones && hPhones) {
    const phoneIsSame = phones.every((phone, idx) => phone === hPhones[idx]);
    const correctPhone = phones.filter((phone) => phone !== '').every((phone) => regex.test(phone));
    const emptyPhone = phones.length === 3 && phones.every((phone) => phone === '');

    disabled = (tel === hTel && phoneIsSame) || !correctPhone || emptyPhone;
  }

  return {
    handleSubmit,
    name,
    tel,
    phones,
    loading: hospitalLoading || updateLoading,
    disabled,
  };
}
