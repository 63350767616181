import React, { CSSProperties } from 'react';
import { Input } from 'antd';
import { LiteralUnion } from 'antd/lib/_util/type';

import './InputLogin.scss';

interface IInputLogin {
  value?: string;
  className?: string;
  type?: LiteralUnion<
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week',
    string
  >;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyup?: (event: React.KeyboardEvent) => void;
  placeholder?: string;
  style?: CSSProperties;
}

export default function InputLogin({
  value,
  className,
  type,
  onChange,
  onBlur,
  onKeyup,
  placeholder,
  style,
}: IInputLogin) {
  return (
    <Input
      value={value}
      className={className}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      onKeyUp={onKeyup}
      placeholder={placeholder}
      style={style}
      autoComplete="new-password"
    />
  );
}
