import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button } from 'antd';

import './DisabledHospital.scss';
import { useHistory } from 'react-router-dom';

interface IDisabledHospital {
  visible: boolean;
}

export default function DisabledHospital({ visible }: IDisabledHospital) {
  const history = useHistory();

  return (
    <Modal
      visible={visible}
      // onCancel={onCancel}
      closable={false}
      footer={null}
      width={422}
      bodyStyle={{ padding: '3.2rem 2rem 2rem 2rem' }}
      maskClosable={false}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
    >
      <div className="DisabledHospital__row" style={{ marginBottom: '0.5rem' }}>
        요청에 의해 서비스 이용이 중지되었습니다.
      </div>
      <div className="DisabledHospital__row" style={{ marginBottom: '0.5rem' }}>
        재이용을 원하실 경우, 충전 후
      </div>
      <div className="DisabledHospital__row" style={{ marginBottom: '0.5rem' }}>
        영업 담당자에게 문의주시기 바랍니다.
      </div>
      <Button
        type="primary"
        style={{
          width: '100%',
          height: '6.4rem',
          marginTop: '5rem',
          fontSize: '1.6rem',
          fontWeight: 'bold',
        }}
        onClick={() => history.push('/charge')}
      >
        확인
      </Button>
    </Modal>
  );
}
