import Main from './Main';
import Hospital from './Hospital';
import Reservation from './Reservation';
import Charge from './Charge';

interface IRoute {
  label: string;
  path: string;
  depth: number;
  isVisible: boolean;
  isPrivate: boolean;
  component: any;
  child: IRoute[];
}

export const routeData: IRoute[] = [
  {
    label: '홈',
    path: '/',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: Main,
    child: [],
  },
  {
    label: '예약관리',
    path: '/reservation',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: Reservation,
    child: [],
  },
  {
    label: '병원관리',
    path: '/hospital',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: Hospital,
    child: [],
  },
  {
    label: '충전금관리',
    path: '/charge',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: Charge,
    child: [],
  },
];

class Routes {
  private routeData: IRoute[];

  constructor() {
    this.routeData = routeData;
  }

  getRoutes() {
    return this.flatRoute(this.routeData, []);
  }

  getNavigation() {
    return this.makeNavigation(this.routeData);
  }

  private flatRoute(routeData: IRoute[], routes: IRoute[]) {
    routeData.forEach((route) => {
      if (route.component) {
        routes.push(route);
      }

      this.flatRoute(route.child, routes);
    });

    return routes;
  }

  private makeNavigation(routeData: IRoute[]) {
    return routeData.reduce((acc, route) => {
      if (route.path !== '/') {
        acc.push(route);
      }
      return acc;
    }, [] as IRoute[]);
  }
}

export const routes = new Routes();
