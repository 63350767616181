import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import Root from 'Root';
import 'assets/scss/index.scss';
// import 'assets/antd.less';

// FIXME: antd 때문에 개발할때만 주석으로 막음
ReactDOM.render(
  // <React.StrictMode>
  <Root />,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
