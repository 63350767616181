import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import { Hospital } from './../../generated/graphql';
import { CHANGE_HOSPITAL_INPUT, SET_AUTH, SET_LOGOUT, SET_MY_HOSPITAL } from './auth.actions';
import { IAuth } from './auth.models';

const initialState = {
  email: '',
  hospital: {} as Hospital,
  myHospital: {} as Hospital,
  id: '',
};

// 담당자 번호는 항상3개
const defaultPhones = ['', '', ''];

const authReducer = createReducer<IAuth, RootAction>(initialState, {
  [SET_AUTH]: (state, action) => ({
    ...state,
    ...action.payload,
    hospital: {
      ...action.payload.hospital,
      phones: defaultPhones.map((phone, idx) => {
        return action.payload.hospital.phones?.[idx] || '';
      }),
    },
  }),

  [SET_LOGOUT]: () => initialState,

  [SET_MY_HOSPITAL]: (state, action) => ({
    ...state,
    myHospital: {
      ...action.payload,
      phones: defaultPhones.map((phone, idx) => {
        return action.payload.phones?.[idx] || '';
      }),
      tel: action.payload.tel,
    },
    hospital: {
      ...state.hospital,
      phones: defaultPhones.map((phone, idx) => {
        return action.payload.phones?.[idx] || '';
      }),
      tel: action.payload.tel,
    },
  }),

  [CHANGE_HOSPITAL_INPUT]: (state, action) => ({
    ...state,
    myHospital: { ...state.myHospital, [action.payload.key]: action.payload.value },
  }),
});

export default authReducer;
