import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';

import './index.scss';
import AuthHelper from 'utils/AuthHelper';
import { FindPassword, LoginFooter, ButtonFat, InputLogin, Spinner } from 'components';
import useLogin from 'hooks/useLogin';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false); // 비밀번호 찾기 모달
  const [isValidEmail, setIsValidEmail] = useState(true);

  const { handleSubmit, loading } = useLogin();

  useEffect(() => {
    AuthHelper.resetToken();
  }, []);

  const onChangeEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const emailExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    setIsValidEmail(emailExp.test(e.target.value));
    setEmail(e.target.value);
  }, []);

  const onClickButtonLogin = useCallback(() => {
    handleSubmit({
      variables: {
        email,
        password,
      },
    });
  }, [email, password, handleSubmit]);

  return (
    <div className="login">
      {loading && <Spinner />}
      <div className="login__content">
        <h1 className="login__content--title">Odoctor Partner</h1>
        <div className="login__content--box">
          <InputLogin
            className="form-input__login"
            style={{ marginBottom: '1rem' }}
            type="email"
            value={email}
            onChange={onChangeEmail}
            placeholder="이메일 주소"
          />
          <InputLogin
            className="form-input__login"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyup={(e) => e.key === 'Enter' && onClickButtonLogin()}
            placeholder="비밀번호 입력"
          />
          <div className={classNames('validate-text', { invalid: !isValidEmail })}>
            이메일 형식으로 입력해주세요
          </div>
        </div>
        <div className="login__content--box">
          <ButtonFat
            className="button-fat__login"
            type="primary"
            onClick={onClickButtonLogin}
            disabled={email === '' || password === '' || loading}
          >
            로그인
          </ButtonFat>
          <div className="find-pass">
            <span className="find-pass" onClick={() => setOpen(true)}>
              비밀번호 찾기
            </span>
          </div>
        </div>
      </div>
      <LoginFooter />
      <FindPassword visible={open} onCancel={() => setOpen(false)} />
    </div>
  );
}

export default Login;
