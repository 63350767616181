import { useMutation } from '@apollo/client';

import { SendEmail } from 'query/email.query';

export default function useSendEmail() {
  const [handleSendEmail, { loading }] = useMutation(SendEmail, {
    onCompleted: (res) => console.log(res),
    onError: (error) => console.log('send email error', error),
  });

  return {
    handleSendEmail,
    loading,
  };
}
