interface IMessage {
  [key: string]: {
    message: string;
    description: string;
  };
}

export const ResponseMessage: IMessage = {
  UpdateHospital: {
    message: '병원정보 수정완료',
    description: '병원정보가 성공적으로 수정되었습니다.',
  },
  UpdateReservation: {
    message: '예약정보 변경',
    description: '예약정보가 성공적으로 변경되었습니다.',
  },
  Login: {
    message: '로그인',
    description: '로그인 완료',
  },
  ResetPassword: {
    message: '비밀번호 변경',
    description: '비밀번호가 성공적으로 변경되었습니다.',
  },
  SendEmail: {
    message: '신고하기',
    description: '신고하기 완료',
  },
};

export const ErrorMessage: IMessage = {
  UpdateHospital: {
    message: '병원정보 변경',
    description: '병원정보 변경 실패, 관리자에게 문의해주세요.',
  },
  GetReservations: {
    message: '예약관리 목록',
    description: '예약관리 목록 가져오기 실패, 관리자에게 문의해주세요.',
  },
  Login: {
    message: '로그인 실패',
    description:
      '이메일과 비밀번호를 확인해주세요. 올바르게 입력했음에도 로그인이 되지 않으면 카카오톡으로 문의해주세요.',
  },
  SendEmail: {
    message: '신고하기 실패',
    description: '신고하기 실패, 관리자에게 문의해주세요.',
  },
};
