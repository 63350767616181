import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select, Spin } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';

import './index.scss';
import { DatePicker } from 'components';
import { ChargeAction } from 'modules/charge/charge.models';
import { resetChargeFilter, setChargeFilter } from 'modules/filter/filter.actions';
import { RootState } from 'modules/rootState';
import useFilterModel from 'hooks/useFilterModel';
import useCharge from 'hooks/useCharge';
import RequestCharge from '../Modal/RequestCharge';

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ADLink = (categoryId: string) => {
  switch (categoryId) {
    case '2': // 치과
      // return 'https://www.notion.so/c3231b603e27426ab8100996dc563737';
      return 'https://alike-leech-719.notion.site/2022-97e4dcc7b13d47b993a0a16d998ee59a';
    default:
      // return 'https://www.notion.so/1ca7f9d536fd4ec8a876b31213e1fd72';
      return 'https://alike-leech-719.notion.site/2022-a807b6c60f9d4363b273a584e564a602';
  }
};

export default function ChargeHeader() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const initialMount = useRef(true);
  const { range, action } = useSelector((state: RootState) => state.filter.chargeFilter);
  const { category } = useSelector((state: RootState) => state.auth.hospital);
  const [, chargeFilterModel] = useFilterModel();
  const { getChargeLists, fetchPoint, point } = useCharge();

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
    } else {
      getChargeLists({
        variables: {
          filter: chargeFilterModel,
        },
      });
    }
  }, [range, action]);

  return (
    <div className="CargeHeader__container">
      <div
        className="CargeHeader__container--row"
        style={{ justifyContent: 'space-between', marginBottom: '9.5rem' }}
      >
        {fetchPoint ? (
          <Spin indicator={antIcon} style={{ margin: '0 2rem', lineHeight: '6.9rem' }} />
        ) : (
          <span>
            <span className="CargeHeader__container--point">
              {point.toLocaleString('ko', { maximumFractionDigits: 1 })}
            </span>
            <span className="CargeHeader__container--point-digit">원</span>
          </span>
        )}
        <div>
          <Button
            onClick={() => setOpenModal(true)}
            className="CargeHeader__container--button"
            type="primary"
            style={{ height: '5.6rem' }}
          >
            충전하기
          </Button>
          <Button
            className="CargeHeader__container--button"
            style={{ height: '5.6rem', margin: '0 1rem' }}
          >
            <a
              href="https://www.notion.so/604ba31404ec4477953e8c73b049f151"
              target="_blank"
              rel="noopener noreferrer"
            >
              충전금 안내
            </a>
          </Button>
          <Button className="CargeHeader__container--button" style={{ height: '5.6rem' }}>
            <a href={ADLink(category?.id)} target="_blank" rel="noopener noreferrer">
              광고상품 안내
            </a>
          </Button>
        </div>
      </div>
      <div className="CargeHeader__container--row">
        <DatePicker.RangePicker
          locale={locale}
          onChange={(value) => {
            const calEndDay = value?.map((v, idx) => {
              if (idx === 1) {
                return v?.endOf('day');
              }

              return v?.startOf('day');
            });

            dispatch(setChargeFilter({ key: 'range', value: calEndDay || [null, null] }));
            // console.log({ key: 'range', value: calEndDay || [null, null] });
          }}
          value={range}
          style={{ height: '5.6rem' }}
          ranges={{
            오늘: [dayjs(), dayjs()],
            이번주: [dayjs().startOf('week'), dayjs().endOf('week')],
            이번달: [dayjs().startOf('month'), dayjs().endOf('month')],
            '3개월': [dayjs().subtract(3, 'month'), dayjs()],
            '6개월': [dayjs().subtract(6, 'month'), dayjs()],
            '1년': [dayjs().subtract(12, 'month'), dayjs()],
          }}
        />
        <Select
          style={{ width: '12rem', height: '5.6rem', marginLeft: '1rem' }}
          onChange={(value) => dispatch(setChargeFilter({ key: 'action', value }))}
          showArrow={false}
          value={action}
        >
          {Object.entries(ChargeAction).map(([key, value]) => (
            <Option value={key} key={key}>
              {value}
            </Option>
          ))}
        </Select>
        <div className="CargeHeader__container--clear">
          <span
            onClick={() => {
              dispatch(resetChargeFilter());
              getChargeLists();
            }}
          >
            검색 초기화
          </span>
        </div>
      </div>
      <RequestCharge visible={openModal} onCancel={() => setOpenModal(false)} />
    </div>
  );
}
