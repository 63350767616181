import React, { useEffect } from 'react';

import { ChargeHeader, ChargeTable } from 'components';
import useCharge from 'hooks/useCharge';

export default function Charge() {
  const { getChargeLists, data = [], pagination, loading } = useCharge();

  useEffect(() => {
    getChargeLists();
  }, []);

  return (
    <>
      <ChargeHeader />
      <ChargeTable
        dataSource={data}
        isFetching={loading}
        pagination={pagination}
        getChargeLists={getChargeLists}
      />
    </>
  );
}
