import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import {
  SET_RESERVATION_FILTER,
  RESET_RESERVATION_FILTER,
  SET_CHARGE_FILTER,
  RESET_CHARGE_FILTER,
} from './filter.actions';
import { IFilter } from './filter.models';

const initialReservationFilter = {
  status: 'A01' as const,
  path: 'All' as const,
  range: [null, null],
  name: '',
  phone: '',
};

const initialChargeFilter = {
  range: [null, null],
  action: 'All' as const,
};

const initialState = {
  reservationFilter: initialReservationFilter,
  chargeFilter: initialChargeFilter,
};

const filterReducer = createReducer<IFilter, RootAction>(initialState, {
  [SET_RESERVATION_FILTER]: (state, action) => ({
    ...state,
    reservationFilter: {
      ...state.reservationFilter,
      [action.payload.key]: action.payload.value,
    },
  }),

  [RESET_RESERVATION_FILTER]: (state, action) => {
    if (action.payload === 'filter') {
      return { ...state, reservationFilter: { ...state.reservationFilter, name: '', phone: '' } };
    } else {
      return initialState;
    }
  },

  [SET_CHARGE_FILTER]: (state, action) => ({
    ...state,
    chargeFilter: {
      ...state.chargeFilter,
      [action.payload.key]: action.payload.value,
    },
  }),

  [RESET_CHARGE_FILTER]: (state) => ({ ...state, chargeFilter: initialChargeFilter }),
});

export default filterReducer;
