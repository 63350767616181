import gql from 'graphql-tag';

export const QUERY_GET_RESERVATIONS = gql`
  query GetReservations($filter: EventReservationListFilter, $pagination: PaginationInput) {
    eventReservations(filter: $filter, pagination: $pagination) {
      data {
        id
        username
        userPhonenum
        userBirthYear
        userGender
        userAgeGroup
        reservationPath
        event {
          id
          name
          description
          therapyName
        }
        status
        discountRate
        discountedPrice
        originalPrice
        reservationTime
        memo
        chargePrice
        createdAt
        refund {
          type
        }
        user {
          id
          name
          phonenum
        }
        contactableType
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const MUTATION_UPDATE_RESERVATION = gql`
  mutation UpdateReservation($id: Int!, $input: EventReservationUpdateInput!) {
    updateEventReservation(id: $id, input: $input) {
      id
      username
      userPhonenum
      userBirthYear
      userGender
      reservationPath
      event {
        id
        name
        description
        therapyName
      }
      status
      discountRate
      discountedPrice
      originalPrice
      reservationTime
      memo
      chargePrice
      createdAt
    }
  }
`;
