import gql from 'graphql-tag';

// 충전금 리스트
export const GET_CHARGE_LISTS = gql`
  query GetChargeLists($filter: HospitalPointTransactionListFilter, $pagination: PaginationInput) {
    hospitalPointTransactions(filter: $filter, pagination: $pagination) {
      data {
        id
        action
        description
        transactionType {
          id
          action
          name
          description
        }
        hospitalPoint {
          id
          balance
        }
        amount
        balance
        timestamp
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

// 충전금
export const GET_HOSPITAL_POINT = gql`
  query GetHospitalPoint {
    hospitalPoint {
      id
      balance
      createdAt
      updatedAt
    }
  }
`;
