import React, { useState, useRef } from 'react';
import { MutationHookOptions, QueryLazyOptions } from '@apollo/client';
import { Table as AntdTable, Modal, Input, Select, Menu, Dropdown } from 'antd';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { ColumnsType } from 'antd/lib/table';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import classnames from 'classnames';

import './ReservationTable.scss';
import {
  Path,
  PathStrings,
  Status,
  StatusStrings,
  UserAgeGroup,
} from 'modules/filter/filter.models';
import { DatePicker, Tag, ReportUser } from 'components';
import {
  IReservation,
  Pagination,
  UpdateReservationInput,
} from 'modules/reservation/reservation.models';
import { numberWithCommas } from 'utils/numberWithComma';
import ArrowDropDown from '../../../assets/images/btn-drop-down.svg';
import ButtonMore from '../../../assets/images/btn-more-normal.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'modules/rootState';
import { Refund } from 'modules/charge/charge.models';

const { confirm } = Modal;
const { TextArea } = Input;
const { Option } = Select;

const kakaoSvg = () => (
  <svg width="20" height="20" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45.39 35.4001C45.398 38.0648 44.3505 40.6244 42.4766 42.5189C40.6026 44.4135 38.0547 45.4889 35.39 45.5101H10.27C7.60531 45.4889 5.05739 44.4135 3.18344 42.5189C1.30949 40.6244 0.261989 38.0648 0.270004 35.4001V10.1801C0.261989 7.5153 1.30949 4.95577 3.18344 3.06121C5.05739 1.16664 7.60531 0.0912054 10.27 0.0700684H35.34C38.0047 0.0912054 40.5526 1.16664 42.4266 3.06121C44.3005 4.95577 45.348 7.5153 45.34 10.1801L45.39 35.4001Z"
      fill="#FAE100"
    />
    <path
      d="M22.66 8.62988C13.66 8.62988 6.42001 14.3799 6.42001 21.4699C6.42001 26.0899 9.50001 30.1399 14.12 32.3999C13.78 33.6699 12.89 36.9999 12.72 37.7099C12.49 38.5899 13.04 38.5799 13.4 38.3399C13.76 38.0999 17.85 35.3399 19.65 34.0899C20.6427 34.2424 21.6456 34.3193 22.65 34.3199C31.65 34.3199 38.9 28.5599 38.9 21.4699C38.9 14.3799 31.64 8.62988 22.66 8.62988Z"
      fill="#371D1E"
    />
  </svg>
);

const PandaIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={kakaoSvg} {...props} />
);

interface ITable {
  isFetching: boolean;
  dataSource: IReservation[];
  filterModel: any;
  updateReservation: (option: MutationHookOptions<any, UpdateReservationInput>) => void;
  getReservations: (option: QueryLazyOptions<any>) => void;
  pagination: Pagination;
}

export default function ReservationTable({
  isFetching = false,
  dataSource,
  updateReservation,
  getReservations,
  filterModel,
  pagination,
}: ITable) {
  const cache = useRef<string>('');
  const [showPicker, setShowPicker] = useState<{ [key: string]: boolean }>({});
  const [showReservationTime, setShowReservationTime] = useState<{ [key: string]: boolean }>({});
  const [reportId, setReportId] = useState<string>('');
  const { name: hospitalName } = useSelector((state: RootState) => state.auth.hospital);

  const renderMemoString = (memo: string) => {
    return memo.length > 13 ? (
      <>
        <div>{memo.substring(0, 13)}</div>
        <div className="memo-row">{memo.substring(13)}</div>
      </>
    ) : (
      <>{memo}</>
    );
  };

  const mailTemplate = (record: IReservation) => {
    const mail = 'mailto:partner@odoctor.co.kr?subject=오닥터 DB 환불신청&body=';
    const body = [
      `병원: ${hospitalName}`,
      `접수일시: ${dayjs(record.createdAt).format('YYYY년 MM월 DD일 HH시 mm분')}`,
      `고객이름: ${record.username}`,
      `전화번호: ${record.userPhonenum}`,
      `유입경로: ${record.event.name}`,
      `환불사유: `,
    ];

    return mail + body.map((v) => `${v}%0D%0A`).join('');
  };

  const columns: ColumnsType<IReservation> = [
    {
      title: '신청일시',
      dataIndex: 'createdAt',
      key: 'createdAt',
      className: 'Reservation-table__header--createdAt',
      render: (createdAt) => (
        <>
          <div>{dayjs(createdAt).format('YYYY.MM.DD')}</div>
          <div>{dayjs(createdAt).format('HH:mm')}</div>
        </>
      ),
    },
    {
      title: '이름',
      dataIndex: 'username',
      key: 'username',
      className: 'Reservation-table__header--username',
      render: (username, data) => (
        <>
          <div>{username}</div>
          <div>
            {!data.userBirthYear && data.userAgeGroup && (
              <span>{UserAgeGroup[data.userAgeGroup]}</span>
            )}
            {data.userBirthYear && `${dayjs().year() - data.userBirthYear + 1}세`}
            {data.userGender && (data.userGender === 'male' ? ' 남' : ' 여')}
          </div>
        </>
      ),
    },
    {
      title: '전화번호',
      dataIndex: 'userPhonenum',
      key: 'userPhonenum',
      className: 'Reservation-table__header--userPhonenum',
      ellipsis: true,
      render: (userPhonenum, data) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
            {!data.refund && data.contactableType == 2 && <PandaIcon />}
            {!data.refund && userPhonenum}
          </div>
        );
      },
    },
    {
      title: '경로',
      dataIndex: 'reservationPath',
      key: 'reservationPath',
      className: 'Reservation-table__header--reservationPath',
      ellipsis: true,
      width: 280,
      render: (text: PathStrings, data) => {
        return (
          <span>
            <Tag color={text === 'Weekly' ? 'blue' : 'orange'}>{Path[text]}</Tag>
            <span>{data.event.therapyName}</span>
          </span>
        );
      },
    },
    {
      title: '가격 (할인)',
      dataIndex: 'discountedPrice',
      key: 'discountedPrice',
      className: 'Reservation-table__header--discountedPrice',
      render: (discountedPrice, data) => {
        return (
          <>
            <div>{numberWithCommas(discountedPrice)}</div>
            {discountedPrice && <div>({data.discountRate}%)</div>}
          </>
        );
      },
    },
    {
      title: '예약일정',
      dataIndex: 'reservationTime',
      key: 'reservationTime',
      className: 'Reservation-table__header--reservationTime',
      render: (text, record) => {
        if (text) {
          // 기본적으로 Refund상태이면 예약일정을 보여주지 않는다
          // 하지만 예외적으로 REFUND_DUPLICATION = '환불-중복' 상태는 예약일정을 보여줌
          if (record.refund) {
            if (record.refund.type !== 'REFUND_DUPLICATION') {
              return null;
            }
          }

          return (
            <>
              <div style={{ visibility: 'hidden', width: 0, height: 0 }}>
                <DatePicker
                  open={showReservationTime[`reservationTime-${record.id}`]}
                  disabledDate={(current) => {
                    return current.isBefore(dayjs(), 'day');
                  }}
                  onOpenChange={(visible) =>
                    setShowReservationTime({ [`reservationTime-${record.id}`]: visible })
                  }
                  onOk={(date) => {
                    updateReservation({
                      variables: {
                        id: Number(record.id),
                        input: {
                          reservationTime: date.toISOString(),
                        },
                      },
                    });
                  }}
                  locale={locale}
                  showNow={false}
                  value={dayjs(text)}
                  disabledHours={() => {
                    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 22, 23];
                  }}
                  showTime={{ minuteStep: 30, showSecond: false, format: 'HH:mm' }}
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className="reservationTime-text"
                onClick={() => setShowReservationTime({ [`reservationTime-${record.id}`]: true })}
              >
                <div>{dayjs(text).format('YYYY.MM.DD')}</div>
                <div>{dayjs(text).format('HH:mm')}</div>
              </div>
            </>
          );
        } else {
          return <span />;
        }
      },
    },
    {
      title: '과금액',
      dataIndex: 'chargePrice',
      key: 'chargePrice',
      className: 'Reservation-table__header--chargePrice',
      render: (chargePrice, record) => (
        <div className={classnames({ cancel: record.refund })}>{numberWithCommas(chargePrice)}</div>
      ),
    },
    {
      title: '메모',
      dataIndex: 'memo',
      key: 'memo',
      className: 'Reservation-table__header--memo',
      render: (memo = '', record) => {
        const text = memo || '';
        return (
          <div className="memo-inner">
            <span
              className={text === '' ? 'hasMemo' : 'normalMemo'}
              onClick={() =>
                confirm({
                  icon: null,
                  content: (
                    <TextArea
                      rows={10}
                      defaultValue={text}
                      onChange={(e) => (cache.current = e.target.value)}
                      className="Reservation-popup__memo"
                      placeholder="메모입력"
                    />
                  ),
                  onOk() {
                    updateReservation({
                      variables: {
                        id: Number(record.id),
                        input: {
                          memo: cache.current,
                        },
                      },
                    });

                    cache.current = '';
                  },
                  okText: '저장',
                  cancelText: '닫기',
                  maskClosable: false,
                  className: 'Reservation-popup',
                })
              }
            >
              {text === '' ? '메모 추가' : renderMemoString(text)}
            </span>
          </div>
        );
      },
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      className: 'Reservation-table__header--status',
      render: (status: StatusStrings, record) => {
        if (record.refund) {
          return (
            <div className="Reservation-table__cell--status">
              <Select
                disabled
                className="status-selector"
                size="small"
                style={{ width: '100%' }}
                value={record.refund.type}
              >
                <Option value={record.refund.type}>{Refund[record.refund.type]}</Option>
              </Select>
            </div>
          );
        }

        return (
          <div className="Reservation-table__cell--status">
            <div style={{ visibility: 'hidden', width: 0, height: 0 }}>
              <DatePicker
                open={showPicker[record.id]}
                disabledDate={(current) => {
                  return current.isBefore(dayjs(), 'day');
                }}
                onOpenChange={(visible) => setShowPicker({ [record.id]: visible })}
                format="YYYY년 MM월 DD일 HH시 mm분"
                onOk={(date) => {
                  updateReservation({
                    variables: {
                      id: Number(record.id),
                      input: {
                        reservationTime: date.toISOString(),
                        status: 'V01',
                      },
                    },
                  });
                }}
                locale={locale}
                showNow={false}
                disabledHours={() => {
                  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 22, 23];
                }}
                showTime={{ minuteStep: 30, showSecond: false, format: 'HH:mm' }}
                style={{ width: '100%' }}
              />
            </div>
            <Select
              size="small"
              style={{ width: '100%' }}
              value={status}
              listHeight={369}
              suffixIcon={<img src={ArrowDropDown} />}
              className="status-selector"
              onChange={(value) => {
                if (value === 'V01') {
                  setShowPicker({ [record.id]: true });
                } else {
                  updateReservation({
                    variables: {
                      id: Number(record.id),
                      input: {
                        status: value,
                      },
                    },
                  });
                }
              }}
            >
              {Object.entries(Status)
                .filter(([key]) => key !== 'A01')
                .map(([key, value]) => (
                  <Option value={key} key={key}>
                    {value}
                  </Option>
                ))}
            </Select>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'declaration',
      key: 'declaration',
      className: 'Reservation-table__header--declaration',
      render: (_, record) => {
        if (!record.refund) {
          return (
            <Dropdown
              overlay={
                <Menu style={{ width: '15rem', padding: '2rem', textAlign: 'center' }}>
                  <Menu.Item style={{ padding: 0, fontSize: '1.6rem', lineHeight: '1.6rem' }}>
                    <a onClick={() => setReportId(record.id)}>신고하기</a>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <img src={ButtonMore} alt="더보기" />
            </Dropdown>
          );
        }
      },
    },
  ];

  const antdPagination = {
    current: pagination.currentPage,
    total: pagination.total,
  };

  const [record] = dataSource.filter((data) => data.id === reportId);

  return (
    <>
      <AntdTable<IReservation>
        loading={{ size: 'large', spinning: isFetching }}
        dataSource={dataSource}
        columns={columns}
        rowKey={(record) => record.id}
        pagination={{ ...antdPagination, position: ['bottomCenter'] }}
        onChange={(data) => {
          getReservations({
            variables: {
              filter: filterModel,
              pagination: {
                currentPage: data.current,
              },
            },
          });
        }}
      />
      <ReportUser visible={!!reportId} record={record} onCancel={setReportId} />
    </>
  );
}
