import React, { Dispatch } from 'react';
import { Layout, Menu, Dropdown, Spin } from 'antd';
import { routes } from 'pages/routes';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import './LayoutHeader.scss';
import Logo from '../../../assets/images/footer-logo.svg';
import BtnDropDown from '../../../assets/images/btn-drop-down.svg';
import { IAuth } from 'modules/auth/auth.models';

const { Header } = Layout;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface ILayoutHeader extends RouteComponentProps {
  user: IAuth;
  point: number;
  pointFetching: boolean;
  setShowModal: Dispatch<boolean>;
  handleLogout: (e: any) => void;
}

function LayoutHeader({
  location,
  user,
  point,
  pointFetching,
  setShowModal,
  handleLogout,
}: ILayoutHeader) {
  const menu = (
    <Menu className="app-header__menu">
      <Menu.Item className="app-header__menu--item-dropdown" onClick={() => setShowModal(true)}>
        비밀번호 변경
      </Menu.Item>
      <Menu.Item className="app-header__menu--item-dropdown" onClick={handleLogout}>
        로그아웃
      </Menu.Item>
      <Menu.Item className="app-header__menu--item-dropdown">{user.email}</Menu.Item>
    </Menu>
  );

  return (
    <Header className="app-header">
      <div className="app-header__inner">
        <Link className="app-header__logo" to="/reservation">
          <img src={Logo} alt="오닥터 로고" />
        </Link>
        <Menu mode="horizontal" selectedKeys={[location.pathname]}>
          {routes.getNavigation().map((route) => (
            <Menu.Item key={route.path}>
              <Link to={route.path} className="app-header__menu--link">
                {route.label}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
        <div>
          {!pointFetching && (
            <Link className="header-info-charge" to="/charge">
              {point.toLocaleString('ko', { maximumFractionDigits: 1 })}원
            </Link>
          )}
          <Dropdown
            overlay={menu}
            trigger={['click']}
            getPopupContainer={() => {
              return document.getElementById('area-dropdown-header') as HTMLElement;
            }}
            openClassName="app-header__hospital"
          >
            <span style={{ cursor: 'pointer' }}>
              <span style={{ margin: '0 1rem' }}>
                {user.hospital.name ? (
                  user.hospital.name
                ) : (
                  <Spin indicator={antIcon} style={{ marginRight: '2rem' }} />
                )}
              </span>
              <span className="app-header__hospital--dropdown">
                <img src={BtnDropDown} alt="드롭다운" />
              </span>
            </span>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
}

export default withRouter(LayoutHeader);
