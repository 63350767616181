import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { RootState } from 'modules/rootState';

dayjs.extend(utc);

export default function useFilterModel() {
  const { reservationFilter, chargeFilter } = useSelector((state: RootState) => state.filter);
  const { status, path, range, name, phone } = reservationFilter;
  const { range: chargeRange, action } = chargeFilter;

  const reservationFilterModel = Object.assign(
    {},
    status !== 'A01' && { status },
    path !== 'All' && { path },
    name.trim() !== '' && { username: name },
    phone.trim() !== '' && { userPhonenum: phone },
    range.every((v: any) => v !== null) && {
      date: {
        startDate: range[0].utc(),
        endDate: range[1].utc(),
      },
    }
  );

  const chargeFilterModel = Object.assign(
    {},
    chargeRange.every((v: any) => v !== null) && {
      date: {
        startDate: chargeRange[0].utc(),
        endDate: chargeRange[1].utc(),
      },
    },
    action !== 'All' && { action }
  );

  return [reservationFilterModel, chargeFilterModel];
}
