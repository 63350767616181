import {
  ApolloLink,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  fromPromise,
} from '@apollo/client';
import { onError } from '@apollo/link-error';
import { notification } from 'antd';
import dayjs from 'dayjs';
import decode from 'jwt-decode';
import * as Sentry from '@sentry/browser';

import { MUTATION_REFRESH_TOKEN } from 'query/auth.query';
import AuthHelper from 'utils/AuthHelper';
import { ErrorMessage, ResponseMessage } from 'utils/ResponseMessage';
import { history } from './store';

const URL = process.env.REACT_APP_API_URL;
const httpLink = new HttpLink({ uri: URL });

const refreshTokenClient = new ApolloClient({
  link: from([httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

let isRefreshing = false;
let pendingRequests: any[] = [];

const resolvePendingRequests = () => {
  pendingRequests.forEach((callback: any) => callback());
  pendingRequests = [];
};

// const authLink = new ApolloLink((operation, forward) => {
//   const { token } = AuthHelper.getTokens();

//   // 로그인
//   if (!token) {
//     return forward(operation);
//   }

//   const { exp } = decode(token);
//   const isExpired = dayjs().isAfter(new Date(exp * 1000));

//   if (isExpired) {
//     return fromPromise(
//       refreshTokenClient
//         .mutate({
//           mutation: MUTATION_REFRESH_TOKEN,
//           variables: {
//             refreshToken: AuthHelper.getToken('refreshToken'),
//           },
//         })
//         .catch((error) => {
//           history.replace('/login', { status: 'expired' });
//         })
//     ).flatMap((res: any) => {
//       AuthHelper.setToken('token', res.data.refreshToken.token);
//       AuthHelper.setToken('refreshToken', res.data.refreshToken.refreshToken);
//       operation.setContext(({ headers = {} }: any) => ({
//         headers: {
//           Authorization: `Bearer ${res?.data.refreshToken.token}`,
//         },
//       }));

//       return forward(operation);
//     });
//   } else {
//     operation.setContext(({ headers = {} }: any) => ({
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     }));

//     return forward(operation);
//   }
// });

// auth
const authMiddleware = new ApolloLink((operation, forward) => {
  try {
    const { token, refreshToken } = AuthHelper.getTokens();

    // 로그인
    if (!token) {
      return forward(operation);
    }

    const { exp } = decode(token);
    const isExpired = dayjs().isAfter(new Date(exp * 1000));

    if (isExpired) {
      let forward$;
      if (!isRefreshing) {
        isRefreshing = true;
        forward$ = fromPromise(
          refreshTokenClient
            .mutate({
              mutation: MUTATION_REFRESH_TOKEN,
              variables: { refreshToken },
            })
            .then(({ data }) => {
              AuthHelper.setToken('token', data.refreshToken.token);
              AuthHelper.setToken('refreshToken', data.refreshToken.refreshToken);
              resolvePendingRequests();
              return data;
            })
            .catch((error) => {
              pendingRequests = [];
              notification.error({
                message: '토큰만료',
                description: '토큰이 만료되어 자동 로그아웃 되었습니다.',
                placement: 'bottomRight',
              });
              history.replace('/login', { status: 'expired' });
              return false;
            })
            .finally(() => {
              isRefreshing = false;
            })
        ).filter((value) => Boolean(value));
      } else {
        // 병렬요청의 첫번째를 제외한 나머지
        forward$ = fromPromise(
          new Promise((resolve) => {
            pendingRequests.push(() => resolve(null));
          })
        );
      }

      return forward$.flatMap(() => {
        operation.setContext(({ headers = {} }: any) => ({
          headers: {
            Authorization: `Bearer ${AuthHelper.getToken('token')}`,
          },
        }));
        return forward(operation);
      });
    } else {
      // 토큰만료되지 않은 요청
      operation.setContext(({ headers = {} }: any) => ({
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      }));
    }
  } catch (error) {
    console.error('에러', error);
    return forward(operation);
  }

  return forward(operation);
});

// after request
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response.errors) {
      return response;
    }

    if (ResponseMessage[operation.operationName]) {
      notification.success({
        message: ResponseMessage[operation.operationName].message,
        description: ResponseMessage[operation.operationName].description,
        placement: 'bottomRight',
      });
    }

    return response;
  });
});

const errorCheckLink = onError(({ graphQLErrors, networkError, operation, response, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (ErrorMessage[operation.operationName]) {
        notification.error({
          message: ErrorMessage[operation.operationName].message,
          description: ErrorMessage[operation.operationName].description,
          placement: 'bottomRight',
        });
      } else {
        notification.error({
          message: operation.operationName,
          description: '문제가 지속되면 관리자에게 연락해주세요.',
          placement: 'bottomRight',
        });
      }

      Sentry.captureMessage(JSON.stringify({ operation: operation.operationName, response }));
    });
  }

  if (networkError) {
    console.error('networkError', networkError);
  }
});

const client = new ApolloClient({
  link: from([authMiddleware, errorCheckLink, afterwareLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default client;
