import { takeLatest, all, fork, getContext } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/types';
import { notification } from 'antd';

import AuthHelper from 'utils/AuthHelper';
import { setLogin, SET_LOGIN, SET_LOGOUT } from './auth.actions';

function* workerLogin(action: ReturnType<typeof setLogin>): SagaIterator {
  const history = yield getContext('history');

  const { token, refreshToken } = action.payload.login;
  AuthHelper.setToken('token', token);
  AuthHelper.setToken('refreshToken', refreshToken);

  history.replace('/reservation');
}

function* workerLogout(): SagaIterator {
  const history = yield getContext('history');
  AuthHelper.resetToken();

  notification.success({
    message: '로그아웃',
    description: '로그아웃 완료',
    placement: 'bottomRight',
  });

  history.replace('/login', { status: 'logout' });
}

function* watchLogin() {
  yield takeLatest(SET_LOGIN, workerLogin);
}

function* watchLogout() {
  yield takeLatest(SET_LOGOUT, workerLogout);
}

export default function* authSaga() {
  yield all([fork(watchLogin), fork(watchLogout)]);
}
