import React, { useState } from 'react';
import { Input, Tooltip } from 'antd';
import classNames from 'classnames';

import './InputHospitalInfo.scss';

interface IInputHospitalInfoMultiple {
  displayName: string;
  placeholder?: string;
  phones?: string[];
  changePhones: (value: string, idx: number) => void;
}

const MODEL = Array.from(Array(3).keys());

export default function InputHospitalInfoMultiple({
  displayName,
  placeholder,
  changePhones,
  phones = [],
}: IInputHospitalInfoMultiple) {
  return (
    <div className="InputHospitalInfo__container multiple">
      <h3 className="InputHospitalInfo__container--display-name">{displayName}</h3>
      {MODEL.map((idx) => (
        <InputWrapper
          key={idx}
          idx={idx}
          placeholder={placeholder}
          changePhones={changePhones}
          phones={phones}
        />
      ))}
    </div>
  );
}

interface IInputWrapper {
  placeholder?: string;
  phones?: string[];
  idx: number;
  changePhones: (value: string, idx: number) => void;
}

function InputWrapper({ placeholder, changePhones, idx, phones = [] }: IInputWrapper) {
  const [isValid, setIsValid] = useState(true);
  return (
    <Tooltip
      title="11자리 숫자만 가능합니다 010xxxxxxxx"
      color="red"
      placement="right"
      visible={!isValid}
    >
      <Input
        className={classNames('InputHospitalInfo__container--input multiple', {
          invalid: !isValid,
        })}
        type="text"
        placeholder={placeholder}
        value={phones[idx]}
        maxLength={11}
        onChange={(e) => {
          const regex = /01[01789]\d{4}\d{4}/;
          const isNumber = regex.test(e.target.value);
          setIsValid(isNumber || e.target.value === '');
          changePhones(e.target.value, idx);
        }}
      />
    </Tooltip>
  );
}
