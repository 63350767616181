import { createAction, ActionType } from 'typesafe-actions';

import { Hospital, Huser, LoginMutation } from 'generated/graphql';
import { IAuthPayload } from './auth.models';

export const SET_AUTH = 'SET_AUTH';
export const setAuth = createAction(SET_AUTH)<Huser>();

export const SET_LOGIN = 'SET_LOGIN';
export const setLogin = createAction(SET_LOGIN)<LoginMutation>();

export const SET_LOGOUT = 'SET_LOGOUT';
export const setLogout = createAction(SET_LOGOUT)();

export const CHANGE_HOSPITAL_INPUT = 'CHANGE_HOSPITAL_INPUT';
export const changeHospitalInput = createAction(CHANGE_HOSPITAL_INPUT)<IAuthPayload>();

export const SET_MY_HOSPITAL = 'SET_MY_HOSPITAL';
export const setMyHospital = createAction(SET_MY_HOSPITAL)<Hospital>();

const actions = { setAuth, setLogin, setLogout, changeHospitalInput, setMyHospital };

// action type
export type AuthActions = ActionType<typeof actions>;
