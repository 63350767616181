import gql from 'graphql-tag';

// 로그인
export const MUTATION_LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      refreshToken
      meh {
        id
        email
      }
    }
  }
`;

// meh
export const QUERY_LOGIN_BY_TOKEN = gql`
  query LoginByToken {
    meh {
      id
      email
      hospital {
        id
        name
        location {
          id
          siName
          gunguName
        }
        category {
          id
          name
          description
        }
        hiraCode
        tel
        phones
        createdAt
        updatedAt
        isActive
      }
    }
  }
`;

// 토큰 재발급
export const MUTATION_REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
      meh {
        id
        email
      }
    }
  }
`;

// 비밀번호 변경
export const MUTATION_RESET_PASSWORD = gql`
  mutation ResetPassword($newPassword: String!) {
    resetPassword(newPassword: $newPassword) {
      ok
      error {
        path
        message
      }
    }
  }
`;

// 병원정보
export const QUERY_MY_HOSPITAL = gql`
  query MyHospital {
    myHospital {
      id
      name
      location {
        id
        siName
        gunguName
      }
      category {
        id
        name
        description
      }
      hiraCode
      tel
      phones
      createdAt
      updatedAt
    }
  }
`;

// 병원정보 변경
export const MUTATION_UPDATE_HOSPITAL = gql`
  mutation UpdateHospital($input: HospitalUpdateInput!) {
    updateHospital(input: $input) {
      id
      name
      tel
      phones
      category {
        id
        name
        description
      }
    }
  }
`;
