import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import './index.scss';
import { RootState } from 'modules/rootState';
import { BrowserAlert, ChangePassword, LayoutHeader } from 'components';
import { setAuth, setLogout } from 'modules/auth/auth.actions';
import { checkIE } from 'utils/checkIE';
import { LoginByTokenQuery } from 'generated/graphql';
import { QUERY_LOGIN_BY_TOKEN } from 'query/auth.query';
import KakaoChat from 'components/atoms/KakaoChat';
import useCharge from 'hooks/useCharge';

interface IAppLayout {
  children: React.ReactNode;
}

const { Content } = Layout;

export default function AppLayout({ children }: IAppLayout) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { auth, charge, router } = useSelector((state: RootState) => state);
  const { getHospitalPoint } = useCharge();
  const history = useHistory();

  useEffect(() => {
    const id = setTimeout(() => {
      loginByToken();
      // getHospitalPoint();
    }, 800);

    return () => {
      clearTimeout(id);
    };
  }, []);

  useEffect(() => {
    getHospitalPoint();
  }, [router]);

  const [loginByToken] = useLazyQuery<LoginByTokenQuery>(QUERY_LOGIN_BY_TOKEN, {
    fetchPolicy: 'no-cache',
    onCompleted: (response) => dispatch(setAuth(response.meh as any)),
  });

  const handleLogout = useCallback(() => {
    dispatch(setLogout());
  }, [dispatch]);

  return (
    <Layout style={{ background: '#fff' }}>
      <LayoutHeader
        user={auth}
        point={charge.point}
        pointFetching={charge.pointFetching}
        setShowModal={setShowModal}
        handleLogout={handleLogout}
      />
      {checkIE() && <BrowserAlert />}
      <Content
        className={classnames('site-layout', {
          wide: history.location.pathname === '/reservation',
        })}
      >
        {children}
      </Content>
      <ChangePassword
        visible={showModal}
        onOk={(e) => setShowModal(false)}
        onCancel={(e) => setShowModal(false)}
      />
      <div className="site-layout__chat">
        <KakaoChat />
      </div>
    </Layout>
  );
}
