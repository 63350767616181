import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { routes } from 'pages/routes';
import { AppLayout, NotFound } from 'components';
import AuthHelper from 'utils/AuthHelper';
import ErrorBoundary from 'ErrorBoundary';

interface IPrivateRoute {
  children: React.ReactNode;
  path: string;
}

const auth = false;
function PrivateRoute({ children, ...rest }: IPrivateRoute) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  if (!AuthHelper.validateToken()) {
    return <Redirect to="/login" />;
  }

  return (
    <ErrorBoundary>
      <AppLayout>
        <Switch>
          {routes.getRoutes().map(({ path, component }, idx) => {
            const Component = component as any;
            return (
              <Route path={path} key={idx} exact>
                <Component />
              </Route>
            );
          })}
          <Route path="*" component={NotFound} />
          <PrivateRoute path="/private">
            <h1>i'm private route</h1>
          </PrivateRoute>
        </Switch>
      </AppLayout>
    </ErrorBoundary>
  );
}

export default App;
