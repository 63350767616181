import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import './index.scss';

export default function NotFound() {
  const history = useHistory();

  return (
    <div className="NotFound__container">
      <div className="NotFound__container--main">
        <div className="NotFound__container--title">404 ERROR</div>
        <div className="NotFound__container--desc">요청하신 페이지를 불러오지 못했습니다.</div>
        <div className="NotFound__container--desc">새로고침 (F5) 해주세요.</div>
      </div>
      <div className="NotFound__container--bottom">
        <Button
          className="NotFound__button"
          type="primary"
          onClick={() => history.replace('/reservation')}
        >
          메인페이지
        </Button>
        <Button
          className="NotFound__button"
          style={{ marginLeft: '1rem' }}
          onClick={() => history.goBack()}
        >
          이전 페이지
        </Button>
      </div>
    </div>
  );
}
