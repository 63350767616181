import React from 'react';
import { Modal } from 'antd';

import './FindPassword.scss';
import ButtonFat from 'components/atoms/Button/ButtonFat';
import { KAKAO_CHAT } from 'utils/constants';

interface IFindPassword {
  visible: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export default function FindPassword({ visible = false, onCancel }: IFindPassword) {
  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onCancel}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      bodyStyle={{ padding: '4rem 2rem 2rem 2rem' }}
      footer={null}
      width={422}
    >
      <div className="find-password__row">
        고객센터로 문의를 주시면 본인 확인 후 즉시 처리 가능합니다.
      </div>
      <div className="find-password__row link">
        <a href={KAKAO_CHAT} target="_blank" rel="noopener noreferrer">
          카카오톡 문의하기
        </a>
      </div>
      <ButtonFat className="button-fat" type="primary" onClick={onCancel}>
        확인
      </ButtonFat>
    </Modal>
  );
}
