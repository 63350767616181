export interface IChargeState {
  data: Charge[];
  pagination: Pagination;
  isFetching: boolean;
  point: number;
  pointFetching: boolean;
}

enum Transaction {
  IN_MANAGER = '관리자 조정',
  IN_NORMAL = '정상충전',
  IN_SERVICE = '서비스 충전',
  OUT_EVENT_RESERVATION = '예약 과금',
  OUT_MANAGER = '관리자 조정',
  REFUND_DUPLICATION = '환불-중복',
  REFUND_NO_ADULT = '환불-미성년자',
  REFUND_NO_PHONE = '환불-결번',
  IN_ETC = '환불 - 기타',
}

type TransactionStrings = keyof typeof Transaction;

export enum Refund {
  REFUND_DUPLICATION = '환불-중복',
  REFUND_NO_ADULT = '환불-미성년자',
  REFUND_NO_PHONE = '환불-결번',
  IN_ETC = '환불 - 기타',
}

export type RefundStrings = keyof typeof Refund;

export enum ChargeAction {
  All = '전체',
  in = '충전',
  out = '차감',
}
export type ChargeActionStrings = keyof typeof ChargeAction;

export interface Charge {
  action: ChargeActionStrings;
  amount: number; // 금액
  balance: number; // 금액이 반영된 최종 잔액
  description: string | null;
  hospitalPoint: {
    id: string;
    balance: number; // 결제시점의 병원잔액 (사용하지않음)
  };
  id: string;
  timestamp: Date;
  transactionType: {
    action: string;
    description: string | null;
    id: TransactionStrings;
    name: string;
  };
}

export interface Pagination {
  total: number;
  perPage: number;
  currentPage: number;
  lastPage: number;
}

export interface IResCharge {
  hospitalPointTransactions: {
    data: Charge[];
    pagination: Pagination;
  };
}

export interface IResHospitalPoint {
  hospitalPoint: {
    balance: number;
    createdAt: Date;
    id: string;
    updatedAt: Date;
  };
}
